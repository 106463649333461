import React, { useState } from "react";
import { useSelector } from "react-redux";

import SidebarOption from "./SidebarOption/SidebarOption";
import CollectionsIcon from "@mui/icons-material/Collections";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CategoryIcon from "@mui/icons-material/Category";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { useNavigate } from "react-router-dom";

import "./Sidebar.css";

import Filters from "../Filters/Filters";

import { FilterOutlined } from "@ant-design/icons";

function Sidebar() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [collapseFilters, setCollapseFilters] = useState(false);

  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(localStorage.getItem("translations"));

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const toggleFilters = () => {
    setCollapseFilters(!collapseFilters);
  };

  return (
    <>
      <div className={`sidebar-toggle`} onClick={toggleSidebar}>
        {showSidebar ? <CloseIcon /> : <MenuIcon />}
      </div>
      <div className={`sidebar ${showSidebar ? `show` : ``}`}>
        <div className="options-container">
          {/* <SidebarOption
            Icon={CollectionsIcon}
            title={
              translations.find(
                (translation) => translation.id === "filters.images"
              )?.[language]
            }
            selected={true}
            onClick={() => navigate("/categories")}
          />

          <SidebarOption
            Icon={CategoryIcon}
            title={
              translations.find(
                (translation) => translation.id === "filters.categories"
              )?.[language]
            }
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
              margin: "10px",
            }}
          ></div>
        </div>
      </div>
      <Filters />
    </>
  );
}

export default Sidebar;
