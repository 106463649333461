import React, { useState, useEffect } from "react";

import "./CategoriesPage.css";

import Categories from "../../components/Categories/Categories";
import Category from "../../components/Categories/Category/Category";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";

function CategoriesPage({ url = window.location.href, subCategory = false }) {
  const language = useSelector((state) => state.language.language);
  const direction = useSelector((state) => state.language.direction);
  const translations = JSON.parse(localStorage.getItem("translations"));
  const config = JSON.parse(localStorage.getItem("config"));

  const [categories, setCategories] = useState([]);

  let slug = (url) => new URL(url).pathname.match(/[^\/]+/g).pop();

  const getAllCategoriesApiEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/getAllCategories";

  const getSubCategoriesApiEndpoint =
    process.env.REACT_APP_BACKEND_API_URL + "/api/getSubCategories";

  const fetchData = () => {
    try {
      fetch(`${getAllCategoriesApiEndpoint}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((response) => response.json())
        .then((response_data) => {
          setCategories(response_data);
          console.log("categories", response_data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataSubCategory = () => {
    try {
      fetch(`${getSubCategoriesApiEndpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ parentCategory: slug(window.location.href) }),
      })
        .then((response) => response.json())
        .then((response_data) => {
          setCategories(response_data);
          console.log("categories", response_data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (slug(window.location.href) !== "categories") {
      fetchDataSubCategory();
    } else {
      fetchData();
    }
  }, []);

  return (
    <div className="home">
      <Header selectedLanguage={language} showSearch={true} showLinks={true} />
      <div style={{ paddingTop: "80px" }}>
        {subCategory ? (
          <Breadcrumb
            items={[
              {
                href: "/",
                title: (
                  <HomeOutlined
                    style={{
                      fontSize: "14px",
                    }}
                  />
                ),
              },
              {
                href: "/gallery",
                title: `${
                  translations.find(
                    (translation) => translation.id === "breadcrumb.gallery"
                  )?.[language]
                }`,
              },
              {
                href: "/categories",
                title: `${
                  translations.find(
                    (translation) => translation.id === "breadcrumb.categories"
                  )?.[language]
                }`,
              },
              {
                className: "active_breadcrumb",
                title: `${
                  translations.find(
                    (translation) =>
                      translation.id ===
                      `categories.${slug(window.location.href)}`
                  )?.[language]
                }`,
              },
            ]}
            style={{
              margin: "16px",
              marginBottom: "0px",
              fontSize: "18px",
              direction: direction,
            }}
          />
        ) : (
          <Breadcrumb
            items={[
              {
                href: "/",
                title: (
                  <HomeOutlined
                    style={{
                      fontSize: "14px",
                    }}
                  />
                ),
              },
              {
                href: "gallery",
                title: `${
                  translations.find(
                    (translation) => translation.id === "breadcrumb.gallery"
                  )?.[language]
                }`,
              },
              {
                className: "active_breadcrumb",
                title: `${
                  translations.find(
                    (translation) => translation.id === "breadcrumb.categories"
                  )?.[language]
                }`,
              },
            ]}
            style={{
              margin: "16px",
              marginBottom: "0px",
              fontSize: "18px",
              direction: direction,
            }}
          />
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <div className="categories-page">
              {categories.map((category, index) => (
                <Category
                  key={`category_${category.id}`}
                  title={
                    translations.find(
                      (translation) =>
                        translation.id ===
                        `categories.${category.translationId}`
                    )?.[language]
                  }
                  image_url={category.imgURL}
                  searchTerm={category.searchTerm}
                  id={category.id}
                  hasSubCategories={category.hasSubCategories}
                  translationsId={category.translationId}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CategoriesPage;
