async function fetchData() {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_API_URL + "/api/getTranslations"
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error fetching data:", error);
    throw error; // rethrow the error to handle it further up the chain
  }
}

fetchData()
  .then((data) => {
    localStorage.setItem("translations", JSON.stringify(data));
  })
  .catch((error) => {
    console.error("Error fetching data:", error);
  });

let translations = JSON.parse(localStorage.getItem("translations"));
if (!translations) {
  translations = [];
}

const language = localStorage.getItem("language");

const title = translations.find(
  (translation) => translation.id === "home.title"
)?.[language];

if (title) {
  document.title = title;
} else {
  document.title = "Kal Media";
}
