import React from "react";
import "./ImageDataProperty.css";
import { Tag, Input, theme } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import config from "../../config/config";
import { useSelector, useDispatch } from "react-redux";
import { setImage } from "../../features/imageSlice";
import { useEffect, useState } from "react";

function ImageDataProperty({ property, value, tags, icon, isAiTags = false }) {
  const language = useSelector((state) => state.language.language);
  const translations = JSON.parse(localStorage.getItem("translations"));
  const config = JSON.parse(localStorage.getItem("config"));

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const image = useSelector((state) => state.image.image);
  const [tagsApprover, setTagsApprover] = useState(false);

  // Tags things
  const [addedTags, setAddedTags] = React.useState([]);

  const { token } = theme.useToken();
  const [editInputIndex, setEditInputIndex] = React.useState(-1);
  const [editInputValue, setEditInputValue] = React.useState("");
  const editInputRef = React.useRef(null);
  const [inputValue, setInputValue] = React.useState("");
  const [inputVisible, setInputVisible] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleEditInputConfirm = () => {
    const newTags = [...addedTags];
    newTags[editInputIndex] = editInputValue;
    setAddedTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const handleClose = (removedTag) => {
    const newTags = addedTags.filter((tag) => tag !== removedTag);
    setAddedTags(newTags);
  };

  React.useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  React.useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    // if empty string, return
    if (inputValue === "") {
      return;
    }

    if (inputValue && addedTags.indexOf(inputValue) === -1) {
      setAddedTags([...addedTags, inputValue]);
    }

    let updatedTags = [...tags];

    if (!updatedTags.includes(inputValue)) {
      updatedTags.push(inputValue);
    }

    // if (property == "AI Tags") {
    //   dispatch(
    //     setImage({
    //       ...image,
    //       aiTags: JSON.stringify({ tags: updatedTags }),
    //     })
    //   );
    // } else {
    dispatch(
      setImage({
        ...image,
        tags: { tags: updatedTags },
      })
    );
    // }

    let body = {
      target: isAiTags ? "aiTags" : "tags",
      uuid: image.uuid,
      tags: JSON.stringify({ tags: updatedTags }),
    };

    console.log("body", body);

    fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/updateMediaMetaData`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    // .then((res) => res.json())
    // .then((data) => {
    //   console.log("data", data);
    // })
    // .catch((err) => {
    //   console.log("err", err);
    // });

    setInputVisible(false);
    setInputValue("");
  };

  const showInput = () => {
    setInputVisible(true);
  };
  // End tags things

  useEffect(() => {
    if (user.roles.includes("approver") || user.roles.includes("admin")) {
      setTagsApprover(true);
    } else {
      setTagsApprover(false);
    }
  }, [user]);

  return (
    <div className="image-data-property">
      <div className="property">
        <h3 style={{ color: config.secondaryColor, fontWeight: "600" }}>
          {icon}
          {property}
        </h3>
      </div>
      <div className="value ellipsis">
        <p style={{
          color: config.secondaryColor,
        }}
          className={`text-[${config.secondaryColor}]`}
        >{value}</p>
      </div>
      {/* display regular tags only if tagsApprover=false */}
      {!tagsApprover && (
        <div className="tags">
          {tags &&
            tags.map((tag, index) => (
              <div key={index}>
                <Tag
                  onClick={() => {
                    console.log("tag clicked", tag);
                    // replace " with "" for the search term
                    // let searchTerm = tag.replace(/"/g, ``);
                    let searchTerm = tag;
                    // window.open(`https://press-office-frontend-baxwq2cu6a-zf.a.run.app/gallery?search=${searchTerm}`, "_blank");
                    let currentURL = window.location.href.split("gallery")[0];
                    window.open(
                      `${currentURL}gallery?search=${searchTerm}`,
                      "_self"
                    );
                    // window.open(`${currentURL}gallery?search=${searchTerm}`, "_self");
                  }}
                >
                  {tag}
                </Tag>
              </div>
            ))}
        </div>
      )}

      {/* display tags with delete button if tagsApprover=true */}
      {/* {tagsApprover && (
        <div className="tags-approver">
          {tags &&
            tags.map((tag, index) => (
              <div key={index}>
                <Tag
                  className="tag-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  {tag}
                  <span
                    className="x-mark"
                    onClick={() => {
                      let tags = image.tags;

                      if (tags.tags.includes(tag)) {
                        tags = tags.tags.filter((item) => item !== tag);
                      }

                      dispatch(
                        setImage({
                          ...image,
                          tags: { tags: tags },
                        })
                      );

                      let body = {
                        uuid: image.uuid,
                        tags: JSON.stringify({ tags: tags }),
                      };

                      console.log("body", body);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_API_URL}/api/updateMediaMetaData`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(body),
                        }
                      )
                        .then((res) => res.json())
                        .then((data) => {
                          console.log("data", data);
                        })
                        .catch((err) => {
                          console.log("err", err);
                        });
                    }}
                  >
                    x
                  </span>
                </Tag>
              </div>
            ))}
        </div>
      )} */}

      {tagsApprover && (
        <div className="tags-approver">
          {tags &&
            tags.map((tag, index) => (
              <div key={index}>
                <Tag
                  className="tag-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  {tag}
                  <span
                    className="x-mark"
                    onClick={() => {
                      if (tags.includes(tag)) {
                        tags = tags.filter((item) => item !== tag);
                      }

                      if (isAiTags) {
                        dispatch(
                          setImage({
                            ...image,
                            aiTags: JSON.stringify({ tags: tags }),
                          })
                        );
                      } else {
                        dispatch(
                          setImage({
                            ...image,
                            tags: { tags: tags },
                          })
                        );
                      }

                      let body = {
                        target: isAiTags ? "aiTags" : "tags",
                        uuid: image.uuid,
                        tags: JSON.stringify({ tags: tags }),
                      };

                      console.log("body", body);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_API_URL}/api/updateMediaMetaData`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(body),
                        }
                      );
                      // .then((res) => res.json())
                      // .then((data) => {
                      //   console.log("data", data);
                      // })
                      // .catch((err) => {
                      //   console.log("err", err);
                      // });
                    }}
                  >
                    x
                  </span>
                </Tag>
              </div>
            ))}
          {/* Add <p> tag here */}
          {tags && property != "AI Tags" && (
            <Input
              placeholder={
                translations.find(
                  (translation) => translation.id === "upload.add_tag"
                )?.[language]
              }
              ref={inputRef}
              type="text"
              size="small"
              style={{
                width: "90px",
                height: "30px",
                verticalAlign: "top",
                // display: !uploading ? "flex" : "none",
              }}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              maxLength={100}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ImageDataProperty;

// {
//   inputVisible ? (
//     <Input
//       ref={inputRef}
//       type="text"
//       size="small"
//       style={{
//         width: "78",
//         verticalAlign: "top",
//         // display: !uploading ? "flex" : "none",
//       }}
//       value={inputValue}
//       onChange={handleInputChange}
//       onBlur={handleInputConfirm}
//       onPressEnter={handleInputConfirm}
//       maxLength={100}
//     />
//   ) : (
//     <Tag
//       style={{
//         background: token.colorBgContainer,
//         borderStyle: "dashed",
//         padding: "1px 6px",
//         fontSize: "14px",
//         color: "rgba(0, 0, 0, 0.45)",
//         fontFamily: "Rubik",
//         letterSpacing: "1px",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         // display: !uploading ? "flex" : "none",
//       }}
//       onClick={showInput}
//       className="tags"
//     >
//       <PlusOutlined
//         style={{
//           fontSize: "10px",
//           fontFamily: "Rubik",
//           fontWeight: "bold",
//           color: "rgba(0, 0, 0, 0.45)",
//           marginLeft: "4px",
//         }}
//       />
//       {
//         translations.find(
//           (translation) => translation.id === "upload.add_tag"
//         )?.[language]
//       }
//     </Tag>
//   );
// }
