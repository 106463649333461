import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Categories from "./components/Categories/Categories";
import CategoriesPage from "./pages/CategoriesPage/CategoriesPage";
import GenericPage from "./components/GenericPage/GenericPage";
import Gallery from "./pages/Gallery/Gallery";
import Home from "./pages/Home/Home";
import { ConfigProvider } from "antd";
import ImageViewByID from "./components/ImageViewByID/ImageViewByID";
import ImageView from "./components/ImageView/ImageView";
import { Spin } from "antd";
import UploadScreen from "./components/UploadScreen/UploadScreen";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { useSelector } from "react-redux";
import HeroMobile from "./components/HeroMobile/HeroMobile";

async function fetchData() {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_API_URL + "/api/getTranslations"
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error fetching data:", error);
    throw error; // rethrow the error to handle it further up the chain
  }
}

async function fetchDataConfig() {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_API_URL + "/api/getConfig"
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    if (!data) {
      console.error("Config not found");
    } else {
      const configKeys = Object.keys(data);
      configKeys.forEach((key) => {
        if (data[key] === "true") {
          data[key] = true;
        }

        if (data[key] === "false") {
          data[key] = false;
        }
      });
    }

    return data;
  } catch (error) {
    console.log("Error fetching data:", error);
    throw error; // rethrow the error to handle it further up the chain
  }
}

function App() {
  const [loading, setLoading] = useState(true);
  const [translations, setTranslations] = useState(null);
  const user = useSelector((state) => state.user);
  const direction = useSelector((state) => state.language.direction);
  const language = useSelector((state) => state.language.language);
  const image = useSelector((state) => state.image.image);

  const envString = process.env.REACT_APP_ENV_TEST;

  if (envString) {
    // Split the string into lines
    const lines = envString.split("\n");

    // Create an object to store the environment variables
    const envVariables = {};

    // Iterate through each line
    lines.forEach((line) => {
      // Check if the line contains an environment variable definition
      const match = line.match(/^\s*([A-Za-z_][A-Za-z0-9_]*)\s*=\s*(.*)\s*$/);

      if (match) {
        const key = match[1];
        const value = match[2].replace(/["']/g, ""); // Remove quotes from the value

        // Store the environment variable in the object
        envVariables[key] = value;
      }
    });
  }

  useEffect(() => {
    fetchData()
      .then((data) => {
        localStorage.setItem("translations", JSON.stringify(data));
        // console.log("local storage translations", localStorage.getItem("translations"));
        setTranslations(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    fetchDataConfig()
      .then((data) => {
        // console.log("---------> data", data);
        localStorage.setItem("config", JSON.stringify(data));
        // console.log("local storage config", localStorage.getItem("config"));
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const isReferrerYourWebsite = document.referrer.includes(
    window.location.hostname
  );

  if (loading) {
    return (
      <div
        className="loading-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin color="#00FF00" size="large" />
      </div>
    );
  }

  if (user.roles.includes("photographer") && user.roles.length == 1) {
    return (
      <div style={{ direction: direction }}>
        <Header />
        <div style={{ paddingTop: "80px" }}>
          <center>
            <h2>
              {
                translations.find(
                  (translation) => translation.id === "upload.title"
                )?.[language]
              }
            </h2>
          </center>
          <div
            style={{
              width: "800px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "50px",
              textAlign: "center",
              paddingBottom: "100px",
            }}
          >
            <UploadScreen />
          </div>
          <Footer />
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div className="unsupported-page">
        {/* <div
          className="phone"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#F8395A"
            strokwidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-smartphone"
          >
            <rect width="14" height="20" x="5" y="2" rx="2" ry="2" />
            <path d="M12 18h.01" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#F8395A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-x-circle"
          >
            <circle cx="12" cy="12" r="10" />
            <path d="m15 9-6 6" />
            <path d="m9 9 6 6" />
          </svg>
        </div>

        <div
          className="computer"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#2FD37E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-laptop"
          >
            <path d="M20 16V7a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v9m16 0H4m16 0 1.28 2.55a1 1 0 0 1-.9 1.45H3.62a1 1 0 0 1-.9-1.45L4 16" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#2FD37E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-check-circle-2"
          >
            <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
            <path d="m9 12 2 2 4-4" />
          </svg>
        </div> */}

        <div>
          <HeroMobile />
        </div>
      </div>
      <div className="app">
        <ConfigProvider direction="rtl">
          <div className="app_body">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/categories"
                element={<CategoriesPage subCategory={false} />}
              />
              <Route
                path="/categories/:sub_category"
                element={
                  <CategoriesPage
                    url={window.location.href}
                    subCategory={true}
                  />
                }
              />
              <Route path="/gallery" element={<Gallery />} />
              <Route
                path="/gallery/:uuid"
                element={
                  isReferrerYourWebsite ? (
                    // <ImageView image={image} currentNavigationKey={0} />
                    <ImageViewByID referrer={"gallery"} />
                  ) : (
                    <ImageViewByID />
                  )
                }
              />

              <Route
                path="/about"
                element={
                  <GenericPage
                    title={
                      translations.find(
                        (translation) => translation.id === "about_page_title"
                      )?.[language]
                    }
                    description={
                      translations.find(
                        (translation) =>
                          translation.id === "about_page_description"
                      )?.[language]
                    }
                  />
                }
              />
              <Route
                path="/terms"
                element={
                  <GenericPage
                    title={
                      translations.find(
                        (translation) => translation.id === "terms_page_title"
                      )?.[language]
                    }
                    description={
                      translations.find(
                        (translation) =>
                          translation.id === "terms_page_description"
                      )?.[language]
                    }
                  />
                }
              />
              <Route
                path="/contact"
                element={
                  <GenericPage
                    title={
                      translations.find(
                        (translation) => translation.id === "contact_page_title"
                      )?.[language]
                    }
                    description={
                      translations.find(
                        (translation) =>
                          translation.id === "contact_page_description"
                      )?.[language]
                    }
                  />
                }
              />
            </Routes>
          </div>
        </ConfigProvider>
      </div>
    </Router>
  );
}

export default App;
