import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../App.css";

import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import MetaDecorator from "../../components/MetaDecorator/MetaDecorator";

import InfiniteGallery from "../../components/InfiniteGallery/InfiniteGallery";

import config from "../../config/config";

import UploadScreen from "../../components/UploadScreen/UploadScreen";

function Gallery() {
  const user = useSelector((state) => state.user);
  const [userRoles, setUserRoles] = useState(null);

  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);
  const direction = useSelector((state) => state.language.direction);

  const translations = JSON.parse(localStorage.getItem("translations"));
  const config = JSON.parse(localStorage.getItem("config"));

  if (process.env.REACT_APP_IS_PRIVATE) {
    // console.log("Redux User --->", user);
    if (user.jwt == "") {
      return (
        <div
          className="hero"
          style={{
            backgroundImage: `url(${config.heroBackgroundImage})`,
            color: `${config.white}`,
            height: "100vh",
          }}
        >
          <h1>
            {
              translations.find(
                (translation) => translation.id === "private.error_message"
              )?.[language]
            }
          </h1>

          <div
            className="continue-to-gallery"
            onClick={() => {}}
            style={{
              color: `${config.white}`,
              zIndex: 999,
              fontSize: "18px",
            }}
          >
            <a href="/">
              {
                translations.find(
                  (translation) => translation.id === "private.back_to_home"
                )?.[language]
              }
            </a>
          </div>
        </div>
      );
    } else if (user.roles.length == 0) {
      return (
        <div
          className="hero"
          style={{
            backgroundImage: `url(${config.heroBackgroundImage})`,
            color: `${config.white}`,
            height: "100vh",
          }}
        >
          <h1>
            {
              translations.find(
                (translation) => translation.id === "private.no_relevant_roles"
              )?.[language]
            }
          </h1>

          <div
            className="continue-to-gallery"
            onClick={() => {}}
            style={{
              color: `${config.white}`,
              zIndex: 999,
              fontSize: "18px",
            }}
          >
            <a href="/">
              {
                translations.find(
                  (translation) => translation.id === "private.back_to_home"
                )?.[language]
              }
            </a>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <div className="gallery">
        <Header
          selectedLanguage={language}
          showSearch={true}
          showLinks={true}
          showUpoload={true}
        />
        <div style={{ paddingTop: "80px" }}>
          <div className="gallery_body" style={{ direction: direction }}>
            <div
              className="images-list-body"
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "100.1vh",
                }}
              >
                <Sidebar />
              </div>
              <div
                style={{
                  padding: "20px",
                  paddingTop: "0px",
                  width: "100%",
                }}
              >
                <InfiniteGallery />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer isInsideGallery={true} />
    </>
  );
}

export default Gallery;

//--------------------------< With Anchor Timeline Scrollbar >--------------------------

// import React, { useEffect, useState, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Anchor, Row, Col } from "antd";
// import Header from "../../components/Header/Header";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import Footer from "../../components/Footer/Footer";
// import InfiniteGallery from "../../components/InfiniteGallery/InfiniteGallery";
// import config from "../../config/config";
// import UploadScreen from "../../components/UploadScreen/UploadScreen";

// function Gallery() {
//   const [targetOffset, setTargetOffset] = useState();
//   const user = useSelector((state) => state.user);
//   const dispatch = useDispatch();
//   const language = useSelector((state) => state.language.language);
//   const direction = useSelector((state) => state.language.direction);
//   const translations = JSON.parse(localStorage.getItem("translations"));

//   const topRef = useRef(null);

//   useEffect(() => {
//     // title height - 10px of padding
//     setTargetOffset(topRef.current?.clientHeight);
//   }, []);

//   // Anchor Timeline Scrollbar

//   const titlesTranslations = [
//     "today",
//     "yesterday",
//     "this_week",
//     "last_week",
//     "this_month",
//     "last_month",
//     "3_months",
//     "6_months",
//     "1_year",
//     "3_years",
//     "5_years",
//     "7_years",
//     "10_years",
//     "20_years",
//     "more_than_a_week",
//   ];

//   // Build items dictionary
//   const items = titlesTranslations.map((title) => ({
//     title: translations.find(
//       (translation) => translation.id === `groups.${title}`
//     )?.[language],
//     key: `groups_${title}`,
//     href: `#groups_${title}`,
//   }));

//   // Initialize filteredItems using useState
//   const [filteredItems, setFilteredItems] = useState([]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       let filteredItems = items.filter((item) => {
//         if (document.getElementById(item.key)) {
//           return item;
//         }
//       });
//       setFilteredItems(filteredItems);
//     }, 1000);
//     return () => clearInterval(interval);
//   }, [items]);

//   if (process.env.REACT_APP_IS_PRIVATE) {
//     if (user.jwt === "") {
//       return (
//         <div
//           className="hero"
//           style={{
//             backgroundImage: `url(${config.heroBackgroundImage})`,
//             color: `${config.white}`,
//             height: "100vh",
//           }}
//         >
//           <h1>
//             {
//               translations.find(
//                 (translation) => translation.id === "private.error_message"
//               )?.[language]
//             }
//           </h1>

//           <div
//             className="continue-to-gallery"
//             onClick={() => {}}
//             style={{
//               color: `${config.white}`,
//               zIndex: 999,
//               fontSize: "18px",
//             }}
//           >
//             <a href="/">
//               {
//                 translations.find(
//                   (translation) => translation.id === "private.back_to_home"
//                 )?.[language]
//               }
//             </a>
//           </div>
//         </div>
//       );
//     } else if (user.roles.length === 0) {
//       return (
//         <div
//           className="hero"
//           style={{
//             backgroundImage: `url(${config.heroBackgroundImage})`,
//             color: `${config.white}`,
//             height: "100vh",
//           }}
//         >
//           <h1>
//             {
//               translations.find(
//                 (translation) => translation.id === "private.no_relevant_roles"
//               )?.[language]
//             }
//           </h1>

//           <div
//             className="continue-to-gallery"
//             onClick={() => {}}
//             style={{
//               color: `${config.white}`,
//               zIndex: 999,
//               fontSize: "18px",
//             }}
//           >
//             <a href="/">
//               {
//                 translations.find(
//                   (translation) => translation.id === "private.back_to_home"
//                 )?.[language]
//               }
//             </a>
//           </div>
//         </div>
//       );
//     }
//   }

//   return (
//     <div
//       style={{
//         direction: direction,
//       }}
//     >
//       <Header selectedLanguage={language} />
//       <Row>
//         <Col
//           span={3}
//           style={{
//             // height: "100.1vh",
//           }}
//         >
//           <Sidebar />
//         </Col>
//         <Col span={21}>
//           <Anchor
//             direction="horizontal"
//             targetOffset={targetOffset}
//             style={{
//               backgroundColor: "#FFF",
//               paddingTop: "10px",
//               paddingBottom: "10px",
//             }}
//             items={filteredItems}
//           />
//           <InfiniteGallery />
//         </Col>
//         {/* if direction==rtl */}
//         {/* { direction == "rtl" ? (
//           <Col span={2} style={{
//             position: "fixed",
//             left: "10px",
//           }}>
//             <Anchor
//               direction="vertical"
//               targetOffset={targetOffset}
//               style={{
//                 backgroundColor: "#FFF",
//                 paddingTop: "10px",
//                 paddingBottom: "10px",
//               }}
//               items={filteredItems}
//             />
//           </Col>
//         ) : (
//           <Col span={2} style={{
//             position: "fixed",
//             right: "10px",
//           }}>
//             <Anchor
//               direction="vertical"
//               targetOffset={targetOffset}
//               style={{
//                 backgroundColor: "#FFF",
//                 paddingTop: "10px",
//                 paddingBottom: "10px",
//               }}
//               items={filteredItems}
//             />
//           </Col>
//         )} */}
//       </Row>
//       <Footer />
//     </div>
//   );
// }

// export default Gallery;
