import React, { useState, useEffect } from "react";
import "./ImageDataPropertyDesciption.css";
import { Tag, message } from "antd";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import config from "../../config/config";

function ImageDataPropertyDesciption({ property, value, tags, icon }) {
  const [editableValue, setEditableValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  const [descriptionApprover, setDescriptionApprover] = useState(false);

  const language = useSelector((state) => state.language.language);
  const direction = useSelector((state) => state.language.direction);
  const translations = JSON.parse(localStorage.getItem("translations"));
  const config = JSON.parse(localStorage.getItem("config"));

  const user = useSelector((state) => state.user);
  const image = useSelector((state) => state.image.image);

  const editIcon = document.querySelector(
    ".image-data-property .description-value .edit-icon"
  );

  useEffect(() => {
    if (user.roles.includes("approver") || user.roles.includes("admin")) {
      setDescriptionApprover(true);
      setEditableValue(
        image[
          `description${language.charAt(0).toUpperCase() + language.slice(1)}`
        ]
      );
    } else {
      setDescriptionApprover(false);
    }
  }, [user, image]);

  const handleEditClick = () => {
    setIsEditing(true);
    // Add classList "is-editing"
    document
      .querySelector(".image-data-property .description-value")
      .classList.add("is-editing");
  };

  const handleBlur = () => {
    setIsEditing(false);
    // Remove classList "is-editing"
    document
      .querySelector(".image-data-property .description-value")
      .classList.remove("is-editing");

    handleSave();
  };

  const handleChange = (event) => {
    const textContent = event.target.textContent;
    setEditableValue(textContent);

    // Set selection range to the end
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(event.target);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleSave = () => {
    setIsEditing(false);

    // success message
    message.success(
      translations.find(
        (translation) => translation.id === "image_data_propery.saved"
      )?.[language]
    );

    // Save Functionallity

    let body = {
      uuid: image.uuid,
      description: editableValue,
    };

    console.log("body", body);

    fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/updateDescription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data", data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="image-data-property">
      <div className="property">
        <h3 style={{ color: config.secondaryColor, fontWeight: "600" }}>
          {icon}
          {property}
        </h3>
      </div>

      {descriptionApprover && (
        <div className={`description-value ${isEditing ? "is-editing" : ""}`}>
          {isEditing ? (
            <div className="editable-container">
              <p
                style={{
                  color: config.secondaryColor,
                }}
                contentEditable
                onBlur={handleBlur}
                onInput={handleChange}
              >
                {editableValue}
              </p>
              {/* <div className="icon-container">
                <button onClick={handleSave} className="save-icon">
                  <SaveOutlined />
                </button>
              </div> */}
            </div>
          ) : (
            <div className="editable-container">
              <p style={{
          color: config.secondaryColor,
        }}>
                {editableValue ||
                  translations.find(
                    (translation) =>
                      translation.id === "image_data_propery.editable"
                  )?.[language]}
              </p>
              <div className="icon-container">
                <button onClick={handleEditClick} className="edit-icon">
                  <EditOutlined />
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {!descriptionApprover && (
        // if user is not approver, then display the value only (not editable)
        <div className="description-value">
          <p>{value}</p>
        </div>
      )}
      <div className="tags">
        {tags &&
          tags.map((tag, index) => (
            <div key={index} style={{ display: "inline-block", margin: "3px" }}>
              <Tag>{tag}</Tag>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ImageDataPropertyDesciption;
