import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ImageView.css";
import { IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";

import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import GroupIcon from "@mui/icons-material/Group";

import { useSelector, useDispatch } from "react-redux";
import { setImage } from "../../features/imageSlice";
import ImageDataProperty from "../ImageDataProperty/ImageDataProperty";
import ImageDataPropertyDesciption from "../ImageDataPropertyDesciption/ImageDataPropertyDesciption";
import Cookies from "js-cookie";

import { Modal, message } from "antd";
import Terms from "../Terms/Terms";
import config from "../../config/config";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Input } from "antd";
import { Button } from "antd";

function ImageView({ currentNavigationKey, uuid, refferer = null }) {
  const [error, setError] = useState(false);

  const handleVideoError = () => {
    setError(true);
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const image = useSelector((state) => state.image.image);
  const images = useSelector((state) => state.images.images);
  const language = useSelector((state) => state.language.language);
  const direction = useSelector((state) => state.language.direction);
  const translations = JSON.parse(localStorage.getItem("translations"));
  const config = JSON.parse(localStorage.getItem("config"));

  const [isApproverButtonsEnabled, setIsApproverButtonsEnabled] =
    useState(true);

  const [currentNavigationKeyState, setCurrentNavigationKeyState] =
    useState(currentNavigationKey);
  const [isPropertiesVisible, setIsPropertiesVisible] = useState(false);

  const cookiesMinutes = 1;

  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(true);

  const [removeBackImage, setRemoveBackImage] = useState(false);

  const [imageLoaded, setImageLoaded] = useState(false);

  // Set the number of seconds (X) after which you want to remove the back-image
  const secondsToRemoveBackImage = 1; // Change this to your desired value

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setRemoveBackImage(true);
  //   }, secondsToRemoveBackImage * 1000);

  //   // Make sure to clear the timeout if the component unmounts or if the back image is removed
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (removeBackImage) {
  //     const backImage = document.querySelector(".back-image");

  //     if (backImage) {
  //       backImage.remove();

  //       setTimeout(() => {
  //         // add backImage again
  //         const backImage = document.createElement("img");
  //         backImage.src = image.thumbnail;
  //         backImage.className = "back-image";
  //         backImage.alt = "Back";
  //         backImage.onload = () => {
  //           setImageLoaded(true);
  //         };
  //         document.querySelector(".back-front-container").appendChild(backImage);
  //       }
  //       , 100);
  //     }
  //   }
  // }, [removeBackImage]);

  const showModal = () => {
    var body = document.getElementsByTagName("html")[0];
    body.style.overflow = "hidden";
    setModalOpen(true);
  };

  // -----------------< Cookies Terms Modal Start >-----------------
  useEffect(() => {
    if (
      Cookies.get("termsAccepted") !== "true" ||
      Cookies.get("termsAccepted") === undefined
    ) {
      // current url + ?showModal=true
      // window.location.href = `${window.location.href}?showModal=true`;
      showModal();
    }
  }, []);

  // -----------------< COOKIES TERMS MODAL END >-----------------

  useEffect(() => {
    setImageLoaded(false);
  }, [image]);

  useEffect(() => {
    if (image && refferer !== null) {
      buildURL();
    }
  }, [image, refferer]);

  function buildURL() {
    // get the current URL
    var currentURL = window.location.href;

    // get the current query string
    var currentQueryString = window.location.search;

    // replace the current image.uuid with the new one
    var newURL =
      currentURL.split("/").slice(0, -1).join("/") +
      "/" +
      image.uuid +
      currentQueryString;

    // replace current URL with the new one without reloading the page
    window.history.replaceState({}, "", newURL);
  }

  const previousImage = () => {
    setError(false);

    if (currentNavigationKeyState == 0) {
      setCurrentNavigationKeyState(images.length - 1);
    } else {
      setCurrentNavigationKeyState(currentNavigationKeyState - 1);
    }

    if (image) {
      buildURL();
    }

    hideFrontImage();

    setTimeout(() => {
      showFrontImage();
    }, 100);
  };

  const nextImage = () => {
    setError(false);

    if (currentNavigationKeyState >= images.length - 1) {
      setCurrentNavigationKeyState(0);
    } else {
      setCurrentNavigationKeyState(currentNavigationKeyState + 1);
    }

    if (image) {
      buildURL();
    }

    hideFrontImage();

    setTimeout(() => {
      showFrontImage();
    }, 100);
  };

  const hideFrontImage = () => {
    const frontImage = document.querySelector(".front-image");

    if (frontImage) {
      frontImage.style.display = "none";
    }
  };

  const showFrontImage = () => {
    const frontImage = document.querySelector(".front-image");
    if (frontImage) {
      frontImage.style.display = "block";
    }
  };

  useEffect(() => {
    dispatch(setImage(images[currentNavigationKeyState]));
  }, [currentNavigationKeyState, dispatch, images]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const actionsButtons = document.querySelector(".action-buttons-container");

  useEffect(() => {
    if (open) {
      actionsButtons.style.opacity = "1";
    }
  }, [open]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    const currentURL = window.location.href;

    navigator.clipboard.writeText(currentURL);

    message.success(
      translations.find(
        (translation) =>
          translation.id === "share_messages.link_copied_successfully"
      )?.[language]
    );

    setAnchorEl(null);
  };

  const [mailValue, setMailValue] = useState("");
  const handleMailChange = (e) => {
    setMailValue(e.target.value);
  };

  const [isMailModalOpen, setMailModalOpen] = useState(false);
  const showMailModal = () => {
    setMailModalOpen(true);
  };

  const handleMailModalCancel = () => {
    setMailModalOpen(false);
  };

  const handleShareToMail = () => {
    showMailModal();

    setAnchorEl(null);
  };

  const shareToMail = () => {
    const requestData = {
      mail: mailValue,
      links: [image.url],
    };

    console.log(requestData);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    fetch(
      `${process.env.REACT_APP_BACKEND_API_URL}/api/postSendZipToMail`,
      requestOptions
    );

    handleMailModalCancel();

    message.success(
      translations.find(
        (translation) =>
          translation.id === "share_messages.files_sent_successfully"
      )?.[language]
    );

    setAnchorEl(null);
  };

  const handleShareToWhatsapp = () => {
    console.log("TEST");
    shareToWhatsapp();
    setAnchorEl(null);
  };

  const [whatsappLink, setWhatsappLink] = useState("");

  const shareToWhatsapp = () => {
    const requestData = {
      mail: null,
      links: [image.url],
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    fetch(
      `${process.env.REACT_APP_BACKEND_API_URL}/api/postSendZipToMail`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed");
        }
        return response.json();
      })
      .then((downloadLink) => {
        // setWhatsappLink(
        //   `whatsapp://send?text=היי!%0aהנה%20התמונות%20לשיתוף:%0a%0a${downloadLink.url}`
        // );

        const link = document.createElement("a");

        var whatsappMessage =
          translations.find(
            (translation) => translation.id === "whatsapp.message"
          )?.[language] +
          " " +
          downloadLink.url;

        link.href = `https://wa.me/?text=${whatsappMessage}`;
        link.target = "_blank";

        link.click();

        // handleShareButtonClick();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDownloadImage = async () => {
    console.log("download image", image);

    const type =
      image["type"] == "image"
        ? process.env.REACT_APP_PUBLIC_BUCKET_IMAGES
        : process.env.REACT_APP_PUBLIC_BUCKET_VIDEOS;

    const ext = image["fileName"].split(".").pop();

    if (image["type"] == "image") {
      // const url = `${process.env.REACT_APP_PUBLIC_BUCKET_IMAGES}/${image["uuid"]}`;
      const url = `${image["url"]}`;
      // console.log("url", url);
      window.open(url, "_blank");
      return;
    } else {
      // const url = `${process.env.REACT_APP_PUBLIC_BUCKET_VIDEOS}/${image["uuid"]}`;
      const url = `${image["url"]}`;
      window.open(url, "_blank");
      return;
    }
  };

  const handleApproveImage = async () => {
    message.success(
      translations.find(
        (translation) => translation.id === "general.action_in_progress"
      )?.[language]
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/postApprovalData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            jwt: user.jwt,
            links: [image.url],
          }),
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsApproverButtonsEnabled(false);

    message.success(
      translations.find(
        (translation) => translation.id === "approver.accept_message"
      )?.[language]
    );

    setModalOpen(true);

    navigate(`/gallery`);
    window.location.reload();
  };

  const handleDeleteImage = async () => {
    message.success(
      translations.find(
        (translation) => translation.id === "general.action_in_progress"
      )?.[language]
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/postDeleteData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            jwt: user.jwt,
            links: [image.url],
          }),
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    message.success(
      translations.find(
        (translation) =>
          translation.id === "image_view_message.media_deleted_successfully"
      )?.[language]
    );

    setModalOpen(true);

    navigate(`/gallery`);
    window.location.reload();
  };

  const handleDeclineImage = async () => {
    message.success(
      translations.find(
        (translation) => translation.id === "general.action_in_progress"
      )?.[language]
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/postDeclineData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            jwt: user.jwt,
            links: [image.url],
          }),
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsApproverButtonsEnabled(false);

    message.success(
      translations.find(
        (translation) =>
          translation.id === "image_view_message.media_deleted_successfully"
      )?.[language]
    );

    setModalOpen(true);

    navigate(`/gallery`);
    window.location.reload();
  };

  useEffect(() => {
    if (uuid) {
      const getImageEndpoint =
        process.env.REACT_APP_BACKEND_API_URL + "/api/getImage";

      fetch(getImageEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uuid: uuid,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Network response was not ok.");
        })
        .then((data) => {
          dispatch(setImage(data[0]));
        })
        .catch((error) => {
          console.error(
            "An error occurred while fetching image data from the backend API:",
            error
          );
        });
    }
  }, [uuid]);

  // console.log(image);

  if (!image) {
    return (
      <div>
        <h1>Image not found</h1>
        {/* <button onClick={() => window.history.back()}>Back</button> */}
      </div>
    );
  }

  return (
    <div
      className="image-view-large"
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {uuid == null && (
        <div className="previous-image">
          <div>
            <IconButton onClick={previousImage}>
              <NavigateNextIcon
                sx={{
                  fontSize: "60px",
                  color: "#B9B9B9",
                }}
              />
            </IconButton>
          </div>
        </div>
      )}
      <div
        className={`black-container ${
          isPropertiesVisible ? "properties-visible" : ""
        } ${!imageLoaded ? "loading" : ""}`}
        style={{
          direction: direction,
          margin: "0 auto",
        }}
      >
        {/* Conditionally render the properties container */}
        {isPropertiesVisible && (
          <div className="properties-container">
            {/* Close icon */}
            <div
              className="close-icon"
              onClick={() => setIsPropertiesVisible(false)}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CloseIcon
                sx={{ fontSize: "24px", color: "black", cursor: "pointer" }}
              />
            </div>
            {isApproverButtonsEnabled && user.roles.includes("approver") && (
              <div className="aprrover-buttons">
                <button className="approve-button" onClick={handleApproveImage}>
                  {
                    translations.find(
                      (translation) =>
                        translation.id === "image_view.accept_file"
                    )?.[language]
                  }
                  <CheckCircleOutlined />
                </button>

                <button className="delete-button" onClick={handleDeclineImage}>
                  {
                    translations.find(
                      (translation) =>
                        translation.id === "image_view.decline_file"
                    )?.[language]
                  }
                  <DeleteOutlined />
                </button>
              </div>
            )}
            {user.roles.includes("admin") &&
              !user.roles.includes("approver") && (
                <div className="aprrover-buttons">
                  <button className="delete-button" onClick={handleDeleteImage}>
                    {
                      translations.find(
                        (translation) =>
                          translation.id === "image_view.delete_file"
                      )?.[language]
                    }
                    <DeleteOutlined />
                  </button>
                </div>
              )}
            <ImageDataProperty
              property={
                translations.find(
                  (translation) => translation.id === "image_view.photo_name"
                )?.[language]
              }
              value={image["fileName"]}
              icon={
                <InsertPhotoIcon
                  style={{
                    transform: "scale(0.85)",
                  }}
                />
              }
            />
            <ImageDataProperty
              property={
                translations.find(
                  (translation) => translation.id === "image_view.photo_date"
                )?.[language]
              }
              value={new Date(
                parseInt(image["photoDate"]) * 1000
              ).toLocaleDateString("en-GB")}
              icon={
                <DateRangeIcon
                  style={{
                    transform: "scale(0.85)",
                  }}
                />
              }
            />
            {/* <ImageDataProperty
              property={
                translations.find(
                  (translation) => translation.id === "image_view.hebrew_date"
                )?.[language]
              }
              value={image["photoHebrewDate"]}
              icon={<DateRangeIcon />}
            /> */}
            {image["photographer"] != "" && (
              <ImageDataProperty
                property={
                  translations.find(
                    (translation) =>
                      translation.id === "image_view.photographer"
                  )?.[language]
                }
                value={image["photographer"]}
                icon={<CameraAltIcon style={{ transform: "scale(0.85)" }} />}
              />
            )}
            {/* Conditionally render the tags */}
            {/* {config.shouldDisplayTags && (
              <ImageDataProperty
                property={
                  translations.find(
                    (translation) => translation.id === "image_view.keywords"
                  )?.[language]
                }
                tags={
                  typeof image[
                    `tags${
                      language.charAt(0).toUpperCase() + language.slice(1)
                    }`
                  ] === "string"
                    ? JSON.parse(
                        image[
                          `tags${
                            language.charAt(0).toUpperCase() + language.slice(1)
                          }`
                        ]
                      )["tags"]
                    : image[
                        `tags${
                          language.charAt(0).toUpperCase() + language.slice(1)
                        }`
                      ]["tags"]
                }
                icon={
                  <GroupIcon
                    style={{
                      transform: "scale(0.85)",
                    }}
                  />
                }
              />
            )} */}

            {/* NEW DESCRIPTION START */}
            <ImageDataPropertyDesciption
              property={
                translations.find(
                  (translation) => translation.id === "image_view.description"
                )?.[language]
              }
              value={
                image[
                  `description${
                    language.charAt(0).toUpperCase() + language.slice(1)
                  }`
                ]
              }
              icon={
                <InfoIcon
                  style={{
                    transform: "scale(0.85)",
                  }}
                />
              }
            />
            {/* NEW DESCRIPTION END */}

            {/* Conditionally render the tags */}
            {config.shouldDisplayTags && (
              <ImageDataProperty
                property={
                  translations.find(
                    (translation) => translation.id === "image_view.keywords"
                  )?.[language]
                }
                tags={image["tags"]["tags"]}
                icon={
                  <GroupIcon
                    style={{
                      transform: "scale(0.85)",
                    }}
                  />
                }
              />
            )}

            {/* Conditionally render the tags */}
            {config.shouldDisplayTags && image[`aiTags`] && (
              <ImageDataProperty
                isAiTags={true}
                property="AI Tags"
                tags={image["aiTags"]["tags"]}
                icon={
                  <GroupIcon
                    style={{
                      transform: "scale(0.85)",
                    }}
                  />
                }
              />
            )}
            {/* OLD DESCRIPTION START */}
            {/* {image[
              `description${
                language.charAt(0).toUpperCase() + language.slice(1)
              }`
            ] != "" && (
              <ImageDataPropertyDesciption
                property={
                  translations.find(
                    (translation) => translation.id === "image_view.description"
                  )?.[language]
                }
                value={
                  image[
                    `description${
                      language.charAt(0).toUpperCase() + language.slice(1)
                    }`
                  ]
                }
                icon={
                  <InfoIcon
                    style={{
                      transform: "scale(0.85)",
                    }}
                  />
                }
              />
            )} */}
            {/* OLD DESCRIPTION END */}
          </div>
        )}

        <div
          className={`black-container-image ${
            isPropertiesVisible ? "properties-visible" : ""
          } ${!imageLoaded ? "loading" : ""}`}
        >
          {image["type"] == "image" ? (
            <div className="back-front-container">
              <div
                style={{
                  background:
                    "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 82%, rgba(0, 0, 0, 0.4) 100%)",
                  zIndex: 3,
                  width: "100%", // Set your desired width
                  height: "100%", // Set your desired height
                  position: "absolute",
                }}
              />
              <img src={image.url} className="front-image" alt="Front" />
              {removeBackImage ? null : (
                <img
                  src={image.thumbnail}
                  className="back-image"
                  alt="Back"
                  onLoad={() => setImageLoaded(true)}
                />
              )}
              <div className="action-buttons-container">
                <IconButton
                  onClick={() => setIsPropertiesVisible(!isPropertiesVisible)}
                >
                  <InfoIcon
                    sx={{
                      fontSize: "30px",
                      color: "#EFEFEF",
                      borderRadius: "50%",
                      // backgroundColor: "#00000020",
                      padding: "5px",
                    }}
                  />
                </IconButton>

                <IconButton
                  id="basic-button-share"
                  aria-controls={open ? "basic-menu-share" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <ShareOutlinedIcon
                    sx={{
                      fontSize: "30px",
                      color: "#EFEFEF",
                      borderRadius: "50%",
                      // backgroundColor: "#00000020",
                      padding: "5px",
                    }}
                  />
                </IconButton>

                <Menu
                  id="basic-menu-share"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button-share",
                  }}
                >
                  <MenuItem onClick={handleCopyLink}>
                    {
                      translations.find(
                        (translation) =>
                          translation.id === "share_options.copy_link"
                      )?.[language]
                    }
                  </MenuItem>
                  <MenuItem onClick={handleShareToMail}>
                    {
                      translations.find(
                        (translation) =>
                          translation.id === "share_options.send_to_mail"
                      )?.[language]
                    }
                  </MenuItem>
                  <MenuItem onClick={handleShareToWhatsapp}>
                    {
                      translations.find(
                        (translation) =>
                          translation.id === "share_options.send_to_whatsapp"
                      )?.[language]
                    }
                  </MenuItem>
                </Menu>

                <Modal
                  title={
                    translations.find(
                      (translation) =>
                        translation.id === "share_options.send_to_mail"
                    )?.[language]
                  }
                  open={isMailModalOpen}
                  // onOk={handleOk}
                  onCancel={handleMailModalCancel}
                  footer={[]}
                  centered
                  className="upload-modal"
                >
                  <div>
                    <Input
                      placeholder="Mail"
                      style={{
                        marginBottom: "24px",
                      }}
                      onChange={handleMailChange}
                    />
                    <center>
                      <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                          shareToMail();
                        }}
                      >
                        {
                          translations.find(
                            (translation) =>
                              translation.id === "image_view.send"
                          )?.[language]
                        }
                      </Button>
                    </center>
                  </div>
                </Modal>

                <IconButton>
                  <FileDownloadOutlinedIcon
                    sx={{
                      fontSize: "30px",
                      color: "#EFEFEF",
                      borderRadius: "50%",
                      // backgroundColor: "#00000020",
                      padding: "5px",
                    }}
                    onClick={handleDownloadImage}
                  />
                </IconButton>
              </div>
            </div>
          ) : (
            <div className="back-front-container">
              {error ? (
                <h1
                  style={{
                    height: "100%",
                    width: "60vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  {image.fileName.includes("mp4") ||
                  image.fileName.includes("mov") ? (
                    <div>
                      {
                        translations.find(
                          (translation) =>
                            translation.id === "video_error.too_large"
                        )?.[language]
                      }
                    </div>
                  ) : (
                    <div>
                      {
                        translations.find(
                          (translation) =>
                            translation.id === "video_error.not_supported"
                        )?.[language]
                      }
                    </div>
                  )}
                </h1>
              ) : (
                <video
                  autoPlay={true}
                  className="front-image"
                  src={image.url}
                  // src="https://s14.api.pdfcandy.com/fa850dbeeded70e0b33acf82db8ec8a3_transcoded.mp4"
                  controls
                  onError={handleVideoError}
                  poster={image["thumbnail"]}
                />

                // <video
                //   id="video"
                //   controls="controls"
                //   playsinline="true"
                //   webkit-playsinline=""
                //   preload="metadata"
                //   // poster="https://s14.api.videocandy.com/9a32cb0c7bf22038/1709555212085-bad_poster.jpg"
                //   crossorigin="anonymous"
                //   className="front-image"
                // >
                //   <source src={image.url} type="video/mp4" />
                // </video>

                // <video
                //   className="front-image"
                //   controls
                //   autoPlay
                //   onError={handleVideoError}
                // >
                //   <source src={image.url} type="video/mp4" />
                //   Your browser does not support the video tag.
                // </video>
              )}
              <div className="action-buttons-container">
                <IconButton
                  onClick={() => setIsPropertiesVisible(!isPropertiesVisible)}
                >
                  <InfoIcon
                    sx={{
                      fontSize: "30px",
                      color: "#EFEFEF",
                      borderRadius: "50%",
                      // backgroundColor: "#00000020",
                      padding: "5px",
                    }}
                  />
                </IconButton>

                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <ShareOutlinedIcon
                    sx={{
                      fontSize: "30px",
                      color: "#EFEFEF",
                      borderRadius: "50%",
                      // backgroundColor: "#00000020",
                      padding: "5px",
                    }}
                  />
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleCopyLink}>
                    {
                      translations.find(
                        (translation) =>
                          translation.id === "share_options.copy_link"
                      )?.[language]
                    }
                  </MenuItem>
                  <MenuItem onClick={handleShareToMail}>
                    {
                      translations.find(
                        (translation) =>
                          translation.id === "share_options.send_to_mail"
                      )?.[language]
                    }
                  </MenuItem>
                  <MenuItem onClick={handleShareToWhatsapp}>
                    {
                      translations.find(
                        (translation) =>
                          translation.id === "share_options.send_to_whatsapp"
                      )?.[language]
                    }
                  </MenuItem>
                  <MenuItem onClick={handleShareToWhatsapp}>
                    {
                      translations.find(
                        (translation) =>
                          translation.id === "share_options.send_to_whatsapp"
                      )?.[language]
                    }
                  </MenuItem>
                </Menu>

                <Modal
                  title={
                    translations.find(
                      (translation) =>
                        translation.id === "share_options.send_to_mail"
                    )?.[language]
                  }
                  open={isMailModalOpen}
                  // onOk={handleOk}
                  onCancel={handleMailModalCancel}
                  footer={[]}
                  centered
                  className="upload-modal"
                >
                  <div>
                    <Input
                      placeholder="Mail"
                      style={{
                        marginBottom: "24px",
                      }}
                      onChange={handleMailChange}
                    />
                    <center>
                      <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                          shareToMail();
                        }}
                      >
                        {
                          translations.find(
                            (translation) =>
                              translation.id === "image_view.send"
                          )?.[language]
                        }
                      </Button>
                    </center>
                  </div>
                </Modal>

                <IconButton>
                  <FileDownloadOutlinedIcon
                    sx={{
                      fontSize: "30px",
                      color: "#EFEFEF",
                      borderRadius: "50%",
                      // backgroundColor: "#00000020",
                      padding: "5px",
                    }}
                    onClick={handleDownloadImage}
                  />
                </IconButton>
              </div>
            </div>
          )}
        </div>

        {/* Conditionally open the modal */}
        {(!Cookies.get("termsAccepted") ||
          Cookies.get("termsAccepted") !== "true") &&
          config.shouldDisplayModal && (
            <Modal
              open={isModalOpen}
              onCancel={() => {
                const expirationDate = new Date();
                expirationDate.setTime(
                  expirationDate.getTime() + cookiesMinutes * 60 * 1000
                ); // 20 minutes from now

                Cookies.set("termsAccepted", "false", {
                  expires: expirationDate,
                });

                // set open to false
                setModalOpen(false);
                // navigate to home page
                window.location.href = "/";
              }}
              footer={[]}
              centered
              width={"95%"}
              destroyOnClose={true}
              className="upload-modal"
            >
              <Terms
                closeModal={() => {
                  Cookies.set("termsAccepted", "true");
                  setModalOpen(false);
                }}
              />
            </Modal>
          )}
      </div>
      {uuid == null && (
        <div className="next-image">
          <IconButton onClick={nextImage}>
            <NavigateBeforeIcon
              sx={{
                fontSize: "60px",
                color: "#B9B9B9",
              }}
            />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default ImageView;
